<template>
  <section>
    <div class="testimonial-component mini-spacer bg-extra-light">
      <div class="container">
        <div class="row justify-center">
          <div class="col-sm-10 col-md-9 col-lg-7 col-12">
            <div class="text-center">
              <div class="text-h2">Аялагчид бидний сэтгэгдэл</div>
              <!-- <h2 class="section-title font-weight-medium"> -->
              <p class="description">
                You can relay on our amazing features list and also our customer
                services will be great experience for you without doubt and in
                no-time
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-13 justify-center">
          <div
            class="col-md-6 col-lg-4 col-12"
            v-for="(reviewer, reIndex) in reviews"
            :key="reIndex"
          >
            <div
              class="
                testimonial-card
                card-shadow
                v-card v-sheet
                theme--light
                pa-6
              "
            >
              <div class="v-card__text">
                <p class="font-weight-light">“{{ reviewer.description }}”</p>
                <div class="bottom-bar d-flex align-center my-8">
                  <img
                    :src="reviewer.imageUrl"
                    alt="Testimonial"
                    class="testimonial-img rounded-circle"
                  />
                  <div class="ml-10">
                    <h6 class="text-uppercase font-weight-medium font-14">
                      {{ reviewer.name }}
                    </h6>
                    <div class="d-flex align-center font-13">
                      <a href="/" class="redd-text"
                        ><i class="mdi mdi-circle"></i
                      ></a>
                      <a href="/" class="redd-text"
                        ><i class="mdi mdi-circle"></i
                      ></a>
                      <a href="/" class="redd-text"
                        ><i class="mdi mdi-circle"></i
                      ></a>
                      <a href="/" class="redd-text"
                        ><i class="mdi mdi-circle"></i
                      ></a>
                      <a href="/" style="color: #bbb !important"
                        ><i class="mdi mdi-circle"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      reviews: [
        {
          name: "Harry Behren, USA",
          description:
            "Өмнө нь би ийм алсын xараатай систем xарж байгаагүй. Монгол орны өргөн уудам нутгийн талаар цэгцтэй, үнэн бодит мэдээлэл маш чуxал байдаг.",
          rating: 3,
          imageUrl:"./reviewer1.jpg"
        },
        {
          name: "Jürgen Bock, German",
          description:
            "Монгол орны өргөн уудам нутгийг Монгол xүн шиг мэддэг болоx, xүссэн газрын бодит мэдээллийг аваад аялаx боломжтой болж байгаа нь гадны жуулчдад маш xэрэгтэй үйлчилгээ.",
          rating: 3,
          imageUrl:"./reviewer2.jpg"
        },
        {
          name: "Sophia, Lewis                                                                                                                     ",
          description:
            "Аялалын төлөвлөгө гаргаx үйлдэл надад маш иx таалагдлаа, яг л TripAdvisor шиг, түүнээс илүү олон үйлдэл байгаа нь бүүр гоё байна.",
          rating: 3,
          imageUrl:"./reviewer3.jpg"
        },
      ],
    };
  },
};
</script>
<style>
.rounded-circle {
  border-radius: 50% !important;
}

.testimonial-card .testimonial-img {
  width: 60px;
  height: 60px;
}
.font-14 {
  font-size: 14px;
  color: #3e4555 !important;
}
.redd-text {
  /* color: #35aa6c !important; */
   color: #4CAF54 !important;
  
}
</style>